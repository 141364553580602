<template>
  <div id="backdrop" class="hide" />
  <div id="blockTop">
    <div class="logo">
      <Go :to="'/' + $store.state.router.locale" aria-label="Home">
        <Logo />
      </Go>
    </div>
    <div class="flex">
      <Go :to="searchLink" aria-label="Suche" class="item">
        <span class="hidden sm:block">Suche</span>
        <Lupe />
      </Go>
      <a
        id="toggleButton"
        class="item cursor-pointer"
        href="#"
        @click.prevent="toggle()"
      >
        <span class="hidden sm:block">Menü</span>
        <Plus class="plus" />
        <Minus class="minus hidden" />
      </a>
      <div id="nav" class="hide">
        <Go
          v-for="link of navigation"
          :key="link.uri"
          :to="link.uri"
          class="link"
          :class="linkClass(link.uri, link.type)"
          @click="toggle()"
          v-text="link.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/images/Logo Full.svg';
import Lupe from '@/assets/images/Search.svg';
import Plus from '@/assets/images/Expand.svg';
import Minus from '@/assets/images/Minus.svg';
import Scrolllock from '@/hooks/scrolllock';

export default {
  components: {
    Minus,
    Plus,
    Lupe,
    Logo,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
    };
  },
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    search() {
      return this.$store.state.router.layout.search;
    },
    searchLink() {
      return this.$store.state.router.layout.searchLink;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggle() {
      // eslint-disable-next-line no-param-reassign
      if (document.getElementsByTagName('body')[0].classList.contains('open')) {
        document.getElementById('nav').classList.add('hide');
        document.getElementById('backdrop').classList.add('hide');
        document.getElementsByClassName('plus')[0].style.display = 'block';
        document.getElementsByClassName('minus')[0].style.display = 'none';
        setTimeout(() => {
          document.getElementById('nav').style.display = 'none';
          document.getElementById('backdrop').style.display = 'none';
        }, 200);
      } else {
        document.getElementById('nav').style.display = 'block';
        document.getElementById('backdrop').style.display = 'block';
        document.getElementsByClassName('plus')[0].style.display = 'none';
        document.getElementsByClassName('minus')[0].style.display = 'block';
        setTimeout(() => {
          document.getElementById('nav').classList.remove('hide');
          document.getElementById('backdrop').classList.remove('hide');
        }, 200);
      }
      document.getElementsByTagName('body')[0].classList.toggle('open');
    },
    handleClickOutside(event) {
      const nav = document.getElementById('nav');
      const toggleButton = document.getElementById('toggleButton');
      // check if the click was outside the nav and the toggle button
      if (document.getElementsByTagName('body')[0].classList.contains('open') && !nav.contains(event.target) && !toggleButton.contains(event.target)) {
        this.toggle();
      }
    },
    linkClass(uri, type) {
      if (uri === this.$store.state.router.page.meta?.uri && type === 'extern') {
        return 'active extern';
      }

      if (uri === this.$store.state.router.page.meta?.uri) {
        return 'active';
      }

      if (type === 'extern') {
        return 'extern';
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  position: relative;
  width: 100%;
  padding-bottom: px(14);
  @screen md {
    padding-bottom: px(18);
  }
 a {
    display: table;
    flex-direction: column;
     margin-left: -0.5rem;
     @screen sm {
       margin-left: -1rem;
     }
     @screen md {
       margin-left: -1.11rem;
       padding-bottom: px(18);
     }
  }
  svg {
    transition: 0.25s all ease-in-out;
    fill: #FFF;
    width: px(230);
    height: auto;
    @screen sm {
      width: px(280);
    }
    @screen md {
      width: px(380);
    }
    @screen lg {
      width: px(450);
    }
    @screen xl {
      width: px(573);
    }

    &:hover {
      fill: var(--color-blue);
    }

    :deep(tspan) {
      font-weight: 700;
      font-family: 'HelveticaNeue';
    }
  }
}
body.open {
  .plus {
    opacity: 0;
  }
  .minus {
    opacity: 1;
  }
}

#nav.hide {
  opacity: 0;
}
#backdrop.hide {
  opacity: 0;
}
.plus, .minus {
  //transition: 0.25s all ease-in-out;
}
.plus {
  opacity: 1;
}
.minus {
  width: px(15) !important;
  height: px(2) !important;
  @screen sm {
    width: px(20) !important;
    height: px(3) !important;
  }
  @screen md {
    width: px(25) !important;
    height: px(5) !important;
  }
  opacity: 0;
}
#backdrop {
  display: none;
  opacity: 0.29;
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: #009EFF;
  transition: 0.5s ease;
  pointer-events: none;
}
#blockTop {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: var(--color-black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: px(0) px(22) px(0) px(22);
  color: #FFF;
  font-size: px(16);
  @screen sm {
    font-size: px(25);
    padding: px(0) px(32) px(0) px(32);
  }
  @screen md {
    font-size: px(32);
    padding: px(0) px(52) px(0) px(52);
  }
  .item {
    transition: 0.25s all ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      // transition: 0.25s all ease-in-out;
      margin-left: px(14);
      // fill: #FFF;
      width: px(18);
      height: px(18);
      @screen sm {
        width: px(20);
        height: px(20);
      }
      @screen md {
        width: px(25);
        height: px(25);
      }
    }

    &:hover {
      color: var(--color-blue);
      svg {
        fill: var(--color-blue);
      }
    }
  }
  .item + .item {
    margin-left: px(20);
    @screen sm {
      margin-left: px(30);
    }
    @screen md {
      margin-left: px(50);
    }
  }
}
#nav {
  display: none;
  transition: 0.25s all ease-in-out;
  position: absolute;
  z-index: 99;
  width: 100vw;
  color: #FFF;
  flex-direction: column;
  right: 0;
  padding: px(32) px(35) px(32) px(30);
  height: auto;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: var(--color-black);
  top: 100%;
  opacity: 1;
  overflow-y: scroll;
  max-height: calc(100vh - 68px);
  @screen md {
    border-bottom-right-radius: 0px;
    width: auto;
    padding: px(42) px(65) px(62) px(50);
    max-height: calc(100vh - 90px);
  }
  a {
    transition: 0.25s all ease-in-out;
    font-size: px(35);
    line-height: px(45);
    display: table;
    @screen sm {
      font-size: px(40);
      line-height: px(50);
    }
    @screen md {
      // font-size: px(45);
      // line-height: px(55);
    }
    @screen lg {
      // font-size: px(55);
      // line-height: px(65);
    }
    &:hover {
      color: var(--color-blue);
    }
    &.extern {
      display: flex;
      align-items: center;

      @screen md {
        align-items: normal;
      }
      &:after {
        display: block;
        transition: all 0.25s ease-in-out;
        content: url("@/assets/images/PfeilMenu.svg?uri");
        mask-size: 100%;
        mask-repeat: no-repeat;
        margin-left: px(14);
        border-radius: px(99);
        width: px(32);
        height: px(32);
        @screen md {
          width: px(35);
          height: px(35);
        }
        @screen lg {
          width: px(42);
          height: px(42);
        }
      }

      &:hover {
        &:after {
          opacity: 0.8;
        }
      }
    }
  }
}

.inner,
.mobile-nav,
:deep(.Language),
.hamburger {
  pointer-events: all;
}

.link {
  font-weight: 500;
  &.active {
    text-decoration: underline;
  }
}
</style>
